<template>
    <div class="mx-4 my-4 md:mx-8">
        <h1 class="text-3xl mb-5">Relatório de sessão</h1>
        <form class="mb-2">
            <div class="grid grid-cols-12 gap-3">
                <div class="col-span-12 md:col-span-2">
                    <label for="de" class="mt-2 block text-sm font-medium">A partir de</label>
                    <input type="date" @input="event => form.de = event.target.value" :value="form.de" name="de" id="de"
                        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>
                <div class="col-span-12 md:col-span-2">
                    <label for="ate" class="mt-2 block text-sm font-medium">Até</label>
                    <input type="date" @input="event => form.ate = event.target.value" :value="form.ate" name="ate" id="ate"
                        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>
                <div class="col-span-12 md:col-span-2">
                    <label for="ate" class="mt-2 block text-sm font-medium">Operador</label>
                    <v-select v-model="form.operador" :options="operadores" label="nome" :reduce="(p) => p._id" name="operador" id="operador" class="mt-1"></v-select>
                </div>

                <div class="col-span-12 md:col-span-3 xl:col-span-2">
                    <button @click="start" type="button"
                        class="md:mt-9 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
                        <span class="inline-block mr-2">Filtrar</span>
                    </button>
                </div>
                <div class="col-span-12 md:col-span-3 xl:col-span-2">
                    <a type="button" target="_blank"
                        :href="`${url}/v1/operador/download/sessao/?token=${token}&operador=${form.operador ? form.operador : ''}&de=${form.de}&ate=${form.ate}`"
                        class="md:mt-9 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Download</a>
                </div>
            </div>
        </form>
        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col"
                                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Operador
                                    </th>

                                    <th scope="col"
                                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Horário de Login
                                    </th>

                                    <th scope="col"
                                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Horário de Logout
                                    </th>
                                    <th scope="col"
                                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Dia
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="el in lista" :key="el._id">
                                    <td class="text-sm 2xl:text-base px-2 py-3">
                                        <span>{{ el.operador.nome }} - {{ el.operador.email }}</span>
                                    </td>
                                    <td class="text-sm 2xl:text-base text-blue-700 font-semibold px-2 py-3">
                                        <span>{{ el.horarioLogin }}</span>
                                    </td>
                                    <td class="text-sm 2xl:text-base text-red-700 font-semibold px-2 py-3">
                                        <span>{{ el.horarioLogout ? el.horarioLogout : "" }}</span>
                                    </td>
                                    <td class="text-sm 2xl:text-base font-semibold px-2 py-3">
                                        {{ moment(el.dia).format("DD/MM/YYYY") }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage"
                        class="pagination" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";

export default {
    data() {
        return {
            lista: [],
            moment: moment,
            url: `${window.location.hostname === 'uno.trestto.com.br' ? 'https://uno.trestto.com.br/api' : process.env.VUE_APP_API}`,
            token: this.$store.state.token,
            form: {
                de: moment().format("YYYY-MM-DD"),
                ate: moment().format("YYYY-MM-DD"),
                operador: null
            },
            operadores: [],
            page: 1,
            perPage: 15,
            total: 0,
        }
    },
    methods: {
        async setPage(page) {
            this.page = page;

            const skip = (page - 1) * this.perPage;
            const limit = this.perPage;

            const response = await this.$http.post(`/v1/operador/sessoes`, {
                de: this.form.de,
                ate: this.form.ate,
                operador: this.form.operador,
                limit,
                skip,
            });

            if (response.data.success) {
                this.lista = response.data.sessoes;
                this.total = response.data.total;
            }
        },
        async start() {
            this.setPage(1);
        },
    },
    async beforeMount() {
        const operadoresReq = await this.$http.post("/v1/operador/list", { all: true, chatAcesso: true, });
        this.operadores = operadoresReq.data.data;
        this.start();
    }
}
</script>
  